.Product.productPrice {
    font-family: "Yanone Kaffeesatz", sans-serif;
    color: white;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 10px;
    border: solid 0.1rem;
    border-color: white;
    background-color: #c00a27;
    text-transform: uppercase;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
}

.Product.simulationImage {
    height: 250px;
}

.Product.details {
    font-style: italic;
    color: #71706c;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.6;
    max-width: 30rem;
    margin: 0 auto;
}

.Product.container {
    display: flex;
    flex-flow: wrap;
    gap: 2rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.Product.product {
    text-align: center;
    flex: 1;
    padding: 1rem;
    border-radius: 1rem;
    min-width: 15rem;
    flex-basis: 25%;
}

.Product.product:only-child {
    height: 100%;
    width: 100%;
}

.Product.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .Product.filterMenu {
        border: solid 0.1rem;
        padding: 0.5rem 1rem;
        margin: 0rem 1rem 0rem 0rem;
        width: 10rem;
    }

    .Product.filterMenuSelected {
        padding: 0.5rem 1rem;
        border: solid 0.1rem;
        border-color: #cf2e2e;
        background-color: #cf2e2e;
        color: white;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
