.Favorites.clearButton {
    font-family: "Yanone Kaffeesatz", sans-serif;
    color: #c00a27;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: solid 0.1rem;
    border-color: #c00a27;
    /* background-color: #c00a27; */
    text-transform: uppercase;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}


