* {
    box-sizing: border-box;
    /* padding: 0;
    margin: 0; */
}

@font-face {
    font-family: "Yanone Kaffeesatz";
    src: local("Yanone Kaffeesatz"),
        url("./fonts/YanoneKaffeesatz-VariableFont_wght.ttf") format("truetype");
}

body {
    font-family: "Open Sans", HelveticaNeue-Light, "Helvetica Neue Light",
        "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    margin: 0rem;
    text-align: center;
    max-width: 1080px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*color of the logo*/
    background: rgba(239,111,38, 0.2) ;
}

body.active {
    overflow: hidden;
}

.margin05{
    margin: .5rem;
}

a,
a:link,
a:visited,
a:focus {
    text-decoration: none;
    color: black;
}

ul {
    display: block;
    list-style-type:none;
    padding-inline: 0;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    body {
        margin-left: auto;
        margin-right: auto;
        max-width: 1080px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}