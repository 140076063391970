.WelcomePage.container {
    display: flex;
    flex-flow: wrap;
    gap: 2rem 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.WelcomePage.backgroundMenu0 {
    background-image: url("../image/menu/menu1.png");
    background-position: center center;
}

.WelcomePage.backgroundMenu1 {
    background-image: url("../image/menu/menu2.png");
    background-position: left center;
}

.WelcomePage.backgroundMenu2 {
    background-image: url("../image/menu/menu3.png");
    background-position: left center;
}

.WelcomePage.formulaCard {
    color: white;
    background-size: cover;
    box-sizing: border-box;
    height: 200px;
    min-width: 250px;
    flex: 1;
    padding: 1rem;
    border-radius: 1rem;
    min-width: 15rem;
    flex-basis: 25%;
}

.WelcomePage.formulaTitle {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-weight: 300;
    font-size: 2rem;
    margin: 1rem 0 0.5rem 0;
}

.WelcomePage.whitoutDescription {
    margin: 2.5rem 0 0.5rem 0;
}

.WelcomePage.formulaDescription {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border-color: white;
    text-transform: uppercase;
}

.WelcomePage.formulaPrice {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: solid 0.1rem;
    background-color: #c00a27;
    border-color: white;
    text-transform: uppercase;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

.WelcomePage.containercouscous {
    width: 100%;
    background-image: url(../image/couscous.png);
    background-position: center;
}

.WelcomePage.tata {
    /* color: white; */
    margin: 1rem;
    border-radius: 1rem;
    /* background-color: rgba(256, 256, 256, 0.7); */
}

.WelcomePage.couscous {
    background-color: rgba(256, 256, 256, 0.6);
    padding: 1rem 0;
    width: 100%;
}

.WelcomePage.couscousTitle {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 3rem;
    margin: 1rem 0 0.5rem 0;
}

.WelcomePage.coucousDescription {
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 300;
    border-radius: 10px;
    border-color: white;
    /* text-transform: uppercase;*/
}

.WelcomePage.couscousPrice {
    font-family: "Yanone Kaffeesatz", sans-serif;
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: solid 0.1rem;
    background-color: #c00a27;
    border-color: white;
    text-transform: uppercase;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .WelcomePage.couscous {
        background-color: transparent;
        width: 50%;
        padding: 12rem 0 6rem;
    }

    .WelcomePage.WelcomePage.coucousDescription {
        background-color: transparent;
        font-size: 1.4rem;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
