.sideBar {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 12;
    top: 0;
    left: -250px;
    background-color: #cf2e2e;
    padding-top: 60px;
    transition: left 0.5s ease;
}
.Back {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 12;
    top: 0;
    left: -100%;
    background-color: grey;
    overflow: hidden;
    opacity: 80%;
}

.App.container {
    position: relative;
    min-height: 100vh;
}

.App.subcontainer {
    padding-top: 6rem;
}

.sideBar.active {
    left: 0;
}

.Back.active {
    left: 0;
    overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

.App.subcontainer {
    padding-top: 6rem;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .App.subcontainer {
        padding-top: 0rem;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
