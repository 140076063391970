.coverBanner {
    transition: background-image 3s linear;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
}

.Banner.container {
    /* box-shadow: 3px 5px #e9ba23; */
    padding-top:2rem;
}

.slider1 {
    background-image: url("../image/roulette.png");
    background-image: url("../image/roulette.gif");
}


/* .slider1 {
    background-image: url("../image/slider-1.png");
} */

.slider2 {
    background-image: url("../image/slider-2.jpg");
}

.slider3{
    background-image: url("../image/slider-3.jpg");
}

.titleBanner {
    font-family: "Yanone Kaffeesatz", sans-serif;
    color: white;
    top: 40%;
    position: relative;
    width: fit-content;
    margin: auto;
    padding: 1rem 0 0 0;
    font-weight: 400;
    font-size: 3rem;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .Banner.container {
        padding-top:8rem;
    }
    .coverBanner {
        height: 500px;
    }
    .titleBanner {
        font-size: 6rem;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
