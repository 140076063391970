/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

.ProductPage.container {
    width: 100%;
    margin: 1rem 0;
    color: #2d2b2d;
}

.ProductPage.titleProduct {
    border-bottom: 1px solid #71706c;
    width: fit-content;
    text-transform: capitalize;
}

.ProductPage.mobile.titleProduct {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.ProductPage.desktop.titleProduct {
    display: none;
}

.ProductPage.illustration {
    width: 100%;
    /* height: 500px; */
}

.ProductPage.presentation {
    width: 100%;
    /* height: 500px; */
    font-size: 1rem;
    /* color: #2d2b2d; */
    font-weight: 400;
    text-align: left;
    line-height: 1.75rem;
    margin: 0.5rem;
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .ProductPage.container {
        display: inline-flex;
    }

    .ProductPage.illustration {
        width: 100%;
        /* height: 500px; */
    }

    .ProductPage.presentation {
        width: 100%;
    }
    .ProductPage.mobile.titleProduct {
        display: none;
    }
    .ProductPage.desktop.titleProduct {
        display: block;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
