.Filter.filterMenu {
    cursor: pointer;
    padding: 0.3rem;
    margin: 0rem .5rem 0rem 0rem;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    list-style-type: none;
}

.Filter.filterMenuSelected {
    border-bottom: solid .1rem;
    border-color: #cf2e2e;
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .Filter.filterMenu {
        border: solid .1rem;
        padding: 0.5rem 1rem;
        margin: 0rem 1rem 0rem 0rem;
        width: 10rem;
    }

    .Filter.filterMenuSelected {
        padding: 0.5rem 1rem;
        border: solid .1rem;
        border-color: #cf2e2e;
        background-color: #cf2e2e;
        color: white;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}


